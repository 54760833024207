import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import {
    Header, Footer, MainContainer, NavSteps, IconsSolid, ReactAux, Fab, LinkWithIcon,
    Avatar, Button
} from '@jkhy/vsg-design-system';

import PageWelcome from './pages/PageWelcome';
import PageInformation from './pages/PageInformation';
import PageYourInformation from './pages/PageYourInformation';
import PageRequest from './pages/PageRequest';
import PageAutoDetails from './pages/PageAutoDetails';
import PagePersonalDetails from './pages/PagePersonalDetails';
import PagePersonalDetailsFilled from './pages/PagePersonalDetailsFilled';
import PageDeclarations from './pages/PageDeclarations';
import PageDisclosures from './pages/PageDisclosures';
import PageCoApplicants from './pages/PageCoApplicants';
import PageReviewApplication from './pages/PageReviewApplication';
import PageResults from './pages/PageResults';
import PageStatus from './pages/PageStatus';
import PageDemoModal from './pages/PageDemoModal';
import PageAddress from './pages/PageAddress';
import PageBusinessReferences from './pages/PageBusinessReferences';

import PageLoanNumber from './renewals/PageLoanNumber';
import PageRenInformation from './renewals/PageRenInformation';
import PageRenDeclarations from './renewals/PageRenDeclarations';
import PageRenDisclosures from './renewals/PageRenDisclosures';
import PageRenTerms from './renewals/PageRenTerms';
import PageSuccess from './renewals/PageSuccess';

let steps = [
    { text: 'Welcome', path: '/views/pages/1', isActive: false },
    { text: 'Information', path: '/views/pages/2', isActive: false },
    { text: 'Your Information', path: '/views/pages/3', isActive: false },
    { text: 'Request', path: '/views/pages/4', isActive: false },
    { text: 'Auto Details', path: '/views/pages/5', isActive: false },
    { text: 'Personal Details', path: '/views/pages/6', isActive: false },
    { text: 'Personal Details Filled', path: '/views/pages/7', isActive: false },
    { text: 'Declarations', path: '/views/pages/8', isActive: false },
    { text: 'Disclosures', path: '/views/pages/9', isActive: false },
    {
        text: 'Co-Applicants', path: '/views/pages/10-1', isActive: false,
        subSteps: [
            { text: 'One Co-Applicants', path: '/views/pages/10-2', isActive: false },
            { text: 'Two Co-Applicants', path: '/views/pages/10-3', isActive: false },
            { text: 'Three Co-Applicants', path: '/views/pages/10-4', isActive: false },
            { text: 'Four Co-Applicants', path: '/views/pages/10-5', isActive: false },
            { text: 'Five Co-Applicants', path: '/views/pages/10-6', isActive: false },
            { text: 'Six Co-Applicants', path: '/views/pages/10-7', isActive: false },
        ]
    },
    { text: 'Review Application', path: '/views/pages/11', isActive: false },
    { text: 'Results', path: '/views/pages/12', isActive: false },
    { text: 'Status', path: '/views/pages/13', isActive: false },
    { text: 'Business References', path: '/views/pages/14', isActive: false },
];

const stepsRenewals = [
    { text: 'Loan Number: *****-201', path: '/renewals/pages/1', isActive: false },
    { text: 'Loan Number: *****0200', path: '/renewals/pages/2', isActive: false },
    { text: 'Information', path: '/renewals/pages/3', isActive: false },
    { text: 'Declarations', path: '/renewals/pages/4', isActive: false },
    { text: 'Disclosures', path: '/renewals/pages/5', isActive: false },
    { text: 'terms', path: '/renewals/pages/6', isActive: false },
];

const sucessRenewals = [
    { text: 'Submitted', path: '/renewals/pages/1', isActive: false },
];

let stepTo = 1;

type DemoPageState = {
    isFabOpen: boolean,
    successPage: boolean,
    logoConfirmVisible: boolean,
    homeConfirmVisible: boolean
}

class DemoPages extends Component<any, DemoPageState> {
    constructor(props: any) {
        super(props);
        this.state = {
            isFabOpen: false,
            successPage: false,
            logoConfirmVisible: false,
            homeConfirmVisible: false,
        };
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevState.isFabOpen !== this.state.isFabOpen) {
            return;
        }
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    toggleFab = () => {
        this.setState({
            isFabOpen: !this.state.isFabOpen
        })
    }

    closeFab = () => {
        this.setState({
            isFabOpen: false
        })
    }

    getSteps = () => {
        const pathName = window.location.pathname.split('/');
        let pageNumber = +pathName[pathName.length - 1].split('-')[0];

        if (pageNumber > 0 && pageNumber <= steps.length) {
            if (stepTo < pageNumber) {
                stepTo = pageNumber
            };

            steps.map((step) => {
                step.isActive = false;
                if (step.subSteps && step.subSteps.length > 0) {
                    step.subSteps.map((subS) => {
                        subS.isActive = false
                        return subS;
                    });
                }
                return step;
            })

            steps[pageNumber - 1].isActive = true;

            const subSteps = steps[pageNumber - 1].subSteps || [];
            if (steps[pageNumber - 1].subSteps && subSteps.length > 0) {
                steps[pageNumber - 1].subSteps?.map((sub) => {
                    if (sub.path === pathName.join('/') && !sub.isActive) {
                        sub.isActive = true;
                        if (steps[pageNumber - 1].isActive) steps[pageNumber - 1].isActive = false;
                    } else if (sub.isActive) {
                        sub.isActive = false;
                    }
                });
            }

            return steps.slice(0, stepTo);
        }

        return [];
    }

    goToSuccessPage = () => {
        stepTo = 1;
        this.setState({
            successPage: true
        })
    }

    openLogoPopup = () => this.setState({ logoConfirmVisible: true });

    closeLogoPopup = () => this.setState({ logoConfirmVisible: false });

    openHomeLinkPopup = () => this.setState({ homeConfirmVisible: true });

    closeHomeLinkPopup = () => this.setState({ homeConfirmVisible: false });

    getHomePopupContent = (onSuccess: any, onCancel: any) => (
        <>
            <h3 className="mb-2">Go to Client Portal</h3>
            <p className="mb-3">Are you sure you want go leave the page ?</p>
            <div className="d-flex">
                <Button
                    btnType="primary mr-2"
                    link={{ path: '/client-portal',  title: 'Home', ariaLabel: 'Client Portal' }}
                    onClick={onSuccess}
                >
                    Yes
                </Button>
                <Button btnType="secondary" onClick={onCancel}>No</Button>
            </div>

        </>
    )

    render() {
        if (this.props.renewals) {
            steps = stepsRenewals
        }
        if (this.state.successPage) {
            steps = sucessRenewals
        }

        const header = <Header
            links={[
                { text: 'Home', path: '/client-portal', icon: IconsSolid.faHome, isHomeLink: true, title:'Home', ariaLabel: 'Home Page' },
                { text: 'FAQs', path: '#', icon: IconsSolid.faComments, title:'FAQs', ariaLabel: 'Faqs Page' },
                { text: 'Sign Out', path: '#', icon: IconsSolid.faSignOutAlt,title:'Sign out', ariaLabel: 'Sign out' }
            ]}

            homeLinkConfirmation={{
                content: this.getHomePopupContent(this.closeHomeLinkPopup, this.closeHomeLinkPopup),
                isVisible: this.state.homeConfirmVisible,
                onOpen: this.openHomeLinkPopup,
                onClose: this.closeHomeLinkPopup,
            }}

            logoLinkConfirmation={{
                content: this.getHomePopupContent(this.closeLogoPopup, this.closeLogoPopup),
                isVisible: this.state.logoConfirmVisible,
                onOpen: this.openLogoPopup,
                onClose: this.closeLogoPopup,
            }}

            logoAccessibility={{
                title: 'Home',
                ariaLabel: 'Home button'
            }}
        />;

        const navSteps = <NavSteps steps={this.getSteps()} hideStepsNumber />;

        const footer = <Footer
            imgLeft={{ src: `${process.env.PUBLIC_URL}/img/footer/footer-left.svg`, alt: 'BBB logo' }}
            imgCenter={{ src: `${process.env.PUBLIC_URL}/img/footer/footer-center.svg`, alt: 'EHO logo' }}
            imgRight={{ src: `${process.env.PUBLIC_URL}/img/footer/footer-right.svg`, alt: 'FEDIC logo' }}
            topText='All users of our online services are subject to our Privacy Statement and agree to be bound by the Terms of Service. Please review.'
            bottomText='© Copyright 2020. Powered by Jack Henry'
            version="Version 1.22.33.44"
        />

        const getRenewalsRoutes = () => {
            return !this.state.successPage ?
                <Switch>
                    <Route path="/renewals/pages/6" render={props => <PageRenTerms {...props} onSuccess={this.goToSuccessPage} />} />
                    <Route path="/renewals/pages/5" component={PageRenDisclosures} />
                    <Route path="/renewals/pages/4" component={PageRenDeclarations} />
                    <Route path="/renewals/pages/3" component={PageRenInformation} />
                    <Route path="/renewals/pages/2" render={props => <PageLoanNumber {...props} title={stepsRenewals[1].text} nextPage={stepsRenewals[2].path} key={stepsRenewals[1].path} />} />
                    <Route path="/renewals/pages/1" render={props => <PageLoanNumber {...props} title={stepsRenewals[0].text} nextPage={stepsRenewals[1].path} key={stepsRenewals[0].path} />} />
                    <Redirect from="/renewals" to="/renewals/pages/1" />
                </Switch>
                :
                <Switch>
                    <Route path="/renewals/success/1" component={PageSuccess} />
                </Switch>
        }

        return (
            <ReactAux>
                <MainContainer
                    header={header}
                    leftSideElement={navSteps}
                    footer={footer}
                >
                    {
                        this.props.renewals ? getRenewalsRoutes() :
                            <Switch>
                                <Route path="/views/pages/(14|14-1)" component={PageBusinessReferences} />
                                <Route path="/views/pages/13" component={PageStatus} />
                                <Route path="/views/pages/12" component={PageResults} />
                                <Route path="/views/pages/11" component={PageReviewApplication} />
                                <Route path="/views/pages/10-(1|2|3|4|5|6|7)" component={PageCoApplicants} />
                                <Route path="/views/pages/9" component={PageDisclosures} />
                                <Route path="/views/pages/8" component={PageDeclarations} />
                                <Route path="/views/pages/7" component={PagePersonalDetailsFilled} />
                                <Route path="/views/pages/6" component={PagePersonalDetails} />
                                <Route path="/views/pages/5" component={PageAutoDetails} />
                                <Route path="/views/pages/4" component={PageRequest} />
                                <Route path="/views/pages/3" component={PageYourInformation} />
                                <Route path="/views/pages/2" component={PageInformation} />
                                <Route path="/views/pages/1" component={PageWelcome} />
                                <Route path="/views/pages/demo-modal" component={PageDemoModal} />
                                <Route path="/views/pages/address" component={PageAddress} />
                                <Redirect from="/views" to="/views/pages/1" />
                            </Switch>
                    }

                </MainContainer>

                <Fab
                    isOpen={this.state.isFabOpen}
                    onFabClick={this.toggleFab}
                    onFabClose={this.closeFab}
                    headerLogo
                    tolltipText='Help and more'
                    items={[
                        <a href="/#" className="d-flex"><Avatar className="mr-05 ml-05" src="https://www.w3schools.com/howto/img_avatar2.png" alt="avatar" />Chat with us</a>,
                        <LinkWithIcon icon={IconsSolid.faPhoneAlt}><a href="/#">0800 800 800</a></LinkWithIcon>,
                        <a href="/#">Get help</a>,
                        <p className="body2">Small text here</p>,
                    ]}
                />

            </ReactAux>
        );
    }
}

export default DemoPages;